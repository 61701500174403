export default {
  title1: "Προηγμένες λύσεις Internet of Things ",
  title2: "για τον κατασκευαστικό και μεταλλευτικό τομέα",
  agnostic: "Ανεξαρτήτως μεγέθους ή μάρκας",
  hero1:
    "Εάν είστε κατασκευαστική ή μεταλλευτική εταιρία, ο στόλος σας είναι από τα σημαντικότερα κομμάτια της εργασίας σας.",
  hero2: "Εμείς θα σας βοηθήσουμε να τα αξιοποιήσετε στο έπακρον.",
  viewDemo: "Δείτε ένα demo",
  contact: "Επικοινωνία",
  copyright: "© 2021-2022 embneusys. All rights reserved",
  espa:
    "<p>Η παρούσα δράση χρηματοδοτήθηκε στα πλαίσια του προγράμματος " +
    "ΕΠΕΝΔΥΤΙΚΑ ΣΧΕΔΙΑ ΚΑΙΝΟΤΟΜΙΑΣ του ΕΠ ΚΕΝΤΡΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ 2014-2020 έργο με κωδικό <strong>ΚΜΡ6-0076940</strong>.</p>",
  features: {
    title: "Η επικοινωνία με το στόλο σας γίνεται παιχνιδάκι",
    1: {
      title: "Αποστολή",
      paragraph:
        "Από τη στιγμή που η συσκευή της Embneusys τοποθετηθεί πάνω σε ένα μηχάνημα, ξεκινά τη συλλογή δεδομένων, όπως θερμοκρασία εργαλείου, τις ώρες που δούλεψε και τη τοποθεσία του!",
    },
    2: {
      title: "Ανάλυση",
      paragraph:
        "Συλλέγουμε και αναλύουμε τα δεδομένα του στόλου σας δημιουργώντας ξεχωριστά προφίλ εργασίας. Μπορείται εύκολα να παρακολουθήσετε τα εργαλεία σας και τον τρόπο που δουλεόυν στο εργοτάξιο.",
    },
    3: {
      title: "Ανάλαβε δράση!",
      paragraph:
        "Μέσω των προφίλ γίνεται εύκολη η διαχείρηση του ανενεργού στόλου και η προστασία του. Έτσι μειώνεται η κατανάλωση, οι χαμένες εργατοώρες για εύρεση και αποθήκευση, ενώ ελαχιστοποιούνται οι πιθανότητες να κλαπεί κάποιο μηχάνημα",
    },
  },

  easy: {
    title: "Τόσο Εύκολο Όσο Ακούγεται",
    subtitle: "ΑΞΙΟΠΟΙΗΣΕ ΤΟΝ ΣΤΟΛΟ ΣΟΥ ΣΤΟ ΕΠΑΚΡΟΝ",
    1: {
      title: "Αποστολή Αισθητήρων Embneusys",
      paragraph: "Σας αποστέλουμε τους αισθητήρες",
    },
    2: {
      title: "Εγκατάσταση Αισθητήρων",
      paragraph:
        "Τοποθετείστε τους αισθητήρες στα μηχανήματα και εργαλεία που επιθυμείτε με την ειδική κολλά που σας παρέχουμε. Οι συσκευές μας είναι ήδη συνδεδεμένες με τον εταιρικό σας λογαριασμό",
    },
    3: {
      title: "Παρακολούθηση",
      paragraph:
        "Αυτό ήταν, οι συσκευές είναι ήδη σε λειτουργία και μπορείται άμεσα να παρακολουθείτε το στόλο σας",
    },
  },
  efficiency: {
    title: "Ανεβάστε επίπεδα παραγωγικότητας μέσω",
    1: {
      title: "80%",
      paragraph: "καλύτερες οργανωτικές πληροφορίες για κάθε εργοτάξιο",
      color: "#FFE076",
    },
    2: {
      title: "30%",
      paragraph: "Μείωση λειτουργικού χρόνου",
      color: "#93CDFF",
    },
    3: {
      title: "30%",
      paragraph: "μείωση στα κόστη επισκεύων",
      color: "#FFFFFF",
    },
    tabs: {
      1: {
        title: "Μειωμένα έξοδα",
        content:
          "Μειώστε τα έξοδα συντήρησης και κατανάλωσης των εργαλείων και μηχανημάτων σας παρακολουθώντας τη χρήση τους μέσω των προφίλ εργασίας",
      },
      2: {
        title: "Εξοικονόμηση χρόνου",
        content:
          "Δείτε ποια εργαλεία είναι σε χρήση και εντοπίστε άμεσα αυτά που είναι ανενεργά, χωρίς να ξοδέψετε επιπλέον χρόνο για την οργάνωση της εργασιών σας",
      },
      3: {
        title: "ESG Compliance",
        content:
          "Κάντε το πρώτο βήμα στην ESG εποχή με τις αυτοματοποιημένες αναφορές εκπομπής ρύπων για τα μηχανήματα σας",
      },
      4: {
        title: "Ελαχιστοποιείστε τις κλοπές",
        content:
          "Ασφαλίστε τα εργαλεία σας χωρίς κόπο. Ορίστε την ασφαλή περιοχή στο χώρο εργασίας, όπως μία αποθήκη, και εμείς θα σας ενημερώσουμε αν κάτι είναι εκτός χώρου στο τέλος της ημέρας",
      },
    },
  },

  solutions: {
    title: "Συστήματα και Λύσεις για κάθε κλάδο",
    subtitle: "ΕΚΠΛΗΡΩΝΟΥΜΕ ΤΙΣ ΑΝΑΓΚΕΣ ΤΩΝ ΠΕΛΑΤΩΝ ΜΑΣ",
  },

  faq: {
    title: " Συχνές Ερωτήσεις",
    subtitle: "ΜΑΘΕΤΕ ΠΕΡΙΣΣΟΤΕΡΑ",
    1: {
      title: "Πως μπορώ να το εγκαταστήσω;",
      content:
        "Η συσκευή είναι plug and play, απλά προσκολλήστε την πάνω στο μηχάνημα και θα ξεκινήσει τη λειτουργία.",
    },
    2: {
      title: "Πόσο θα μου κοστίσει;",
      content:
        "Διαθέτουμε επιλογές άμεσης αγοράς ή Pay-as-you-go. Επικοινωνήστε μαζί μας για βρούμε τη βέλτιστη επιλογή για τις ανάγκες σας.",
    },
    3: {
      title: "Χρειάζεται να αλλάξω μπαταρίες;",
      content:
        "Η συσκευή είναι επαναφορτιζόμενη. Απλά φορτίστε την όταν σαν ενημερώσει η εφαρμογή.",
    },

    partnership: "Σε συνεργασία με",
    why: "Δείτε μόνοι σας γιατί πετυχημένες εταιρίες επιλέγουν την Embneusys",
    scheduleDemo: "Δείτε ένα demo",
    or: "ή",
    contact: "επικοινωνήστε μαζί μας",
  },

  testimonials: {
    title: "We Are",
    1: {
      person: "Παύλος Κοσμίδης, Ιδιοκτήτης",
      content:
        "“Το Cube μας επέτρεψε να δούμε πως αποδίδουμε τα γεωτρύπανα μας στο πεδίο και πως μπορούμε να τα κάνουμε να δουλεύουν καλύτερα”",
    },
    2: {
      person: "Κώστας Παππάς, Υπεύθυνος Λατομείου",
      content:
        "“Η λύση της Embneusys μας έδωσε μια πρώτη εικόνα για το τι συμβαίνει στο λατομείο και πως μπορούμε να μειώσουμε τα λειτουργικά μας κόστη”",
    },
    3: {
      person: "Johnatan Garcia Gonzalez, Technical Manager",
      content:
        "“Η λύση της Embneusys μας φέρνει ένα βήμα πιο κοντά στην επίτευξη της πράσινης και επιχειρησιακής απόδοσης στη βιομηχανία εξόρυξης.”",
    },
  },
  marquee: {
    title: "ΤΕΛΕΥΤΑΙΑ ΝΕΑ",
    text: "Η επιχείρηση EMBNEUSYS IKE που εδρεύει στην Περιφέρεια Κεντρικής Μακεδονίας εντάχθηκε στη δράση «Στήριξη Νεοφυών Επιχειρήσεων Εθνικού Μητρώου «Elevate Greece» για την αντιμετώπιση της πανδημίας Covid-19 Β- Κύκλος» συνολικού προϋπολογισμού 34 εκατ. Ευρώ.  Η Δράση στοχεύει στην ενίσχυση της επιχειρηματικότητας και της ρευστότητας των νεοφυών/καινοτόμων μικρομεσαίων επιχειρήσεων (που δραστηριοποιούνται σε τομείς της Έξυπνης Εξειδίκευσης/RIS3) του Εθνικού Μητρώου Νεοφυών Επιχειρήσεων «Elevate Greece», υπό τις αντίξοες συνθήκες της κρίσης του COVID-19. Το συνολικό ποσό δημόσιας χρηματοδότησης που έλαβε η επιχείρηση με τη μορφή μη επιστρεπτέας επιχορήγησης ανέρχεται σε 24.111,92€ και συγχρηματοδοτείται από την Ελλάδα και το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης της Ευρωπαϊκής Ένωσης.",
  },
  team: {
    title: "Αυτοί που μοιράζονται το όραμα",
    subtitle: "Και υποστηρίζουν την αποστολή μας",
    1: {
      name: "Γιώργος Φόρογλου (CEO - Πωλήσεις)",
      photo: "@/assets/images/team/foroglou.jpeg",
      linkedin: "https://linkedin.com/in/georgeforoglou/",
      text: "Ο Γιώργος είναι απόφοιτος του Πανεπιστημίου Μακεδονίας. Έχει συμμετάσχει σε χρηματοοικονομικά project (CFA Research Challenge), έχει κάνει πρακτική στο Χρηματιστήριο Αθηνών και έχει λάβει διακρίσεις σε διαγωνισμούς επιχειρηματικότητας και τεχνολογίας (Hult Prize, Hilti IoT Competition)",
    },
    2: {
      name: "Γιώργος Κυριαζίδης (CTO)",
      photo: "@/assets/images/team/kyriazidis.jpeg",
      linkedin: "https://linkedin.com/in/georgios-kyriazidis/",
      text: "Ο Γιώργος είναι υποψήφιος διδάκτορας στο Πανεπιστήμιο Harvard, κατέχει MSc Ηλεκτρολόγου Μηχανικού και έχει κάνει πρακτική στο Hilti R&D",
    },
    3: {
      name: "Λευτέρης Αδαλάκης (COO)",
      photo: "@/assets/images/team/adalakis.jpeg",
      linkedin: "https://linkedin.com/in/eleftherios-adalakis-a0b256158/",
      text: "Ο Λευτέρης έχει μεταπτυχιακό Ηλεκτρολόγου Μηχανικού (Αριστοτέλειο Πανεπιστήμιο), έχει κάνει πρακτική στη Hilti IoT και έχει εργαστεί ως Product Manager στη Hilti Marketing. Αυτή τη στιγμή εργάζεται ως Front End developer στη Cognizant Ελβετίας",
    },
  },
};

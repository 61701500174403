export default {
  title1: "Advanced IoT Solutions",
  title2: "for the Construction and Mining Machinery",
  agnostic: "Agnostic to brand or size",
  hero1:
    "If you are a construction or mining company, you know that machinery is the most essential part of your work.",
  hero2: "We make them count for you and you can take the best out of them.",
  viewDemo: "see the demo",
  contact: "Contact",
  copyright: "© 2021-2022 embneusys. All rights reserved",
  espa:
    "<p>The current work was financed under the Action “Investment Plans of Innovation“ in the " +
    "Region of Central Macedonia, under the framework of the Operational Program “Central Macedonia 2014-2020“, in the work with code <strong>ΚΜΡ6-0076940</strong>.</p>",
  menu: {
    solution: "Our solution",
    about: "About",
    resources: "Resources",
    quote: "Get a Quote",
    faq: "FAQ",
  },

  features: {
    title:
      "Monitoring, Understanding, Tracking Your Equipment Has Never Been Easier",
    1: {
      title: "Receive",
      paragraph:
        "From the moment the sensor is placed on your equipment, it starts sending data. Temperature, location, working hours, you name it, you won’t miss a byte!",
    },
    2: {
      title: "Analyze",
      paragraph:
        "We efficiently analyze your assets, creating working profiles. You can now easily track and locate your equipment," +
        " minimize theft, see how much they’re being used and make them work flawlessly for you.",
    },
    3: {
      title: "Take Action!",
      paragraph:
        "No time to waste! Maximize your capacity by utilizing your idling equipment while bringing down fuel and consumption costs.",
    },
  },

  easy: {
    title: "As Easy As It Sounds",
    subtitle: "KEEP YOUR TEAM AND MACHINERY PERFORMANCE AT PEAK",
    1: {
      title: "Sensor Shipment",
      paragraph: "We send you the sensors ready for deployment and operation",
    },
    2: {
      title: "Sensor Installation",
      paragraph:
        "The sensors are attached effortlessly on every asset on a temporal or permanent basis, regarding its use and condition. " +
        "There is no need for time-costly installations and the sensor can be up and play in less than 10 minutes.",
    },
    3: {
      title: "Monitoring",
      paragraph: "We got it from there",
    },
  },
  efficiency: {
    title: "Bringing Efficiency Through",
    1: {
      title: "80%",
      paragraph: "better organizational insights for every construction site",
      color: "#FFE076",
    },
    2: {
      title: "30%",
      paragraph: "reduction in operational time",
      color: "#93CDFF",
    },
    3: {
      title: "30%",
      paragraph: "reduction in machinery repair costs",
      color: "#FFFFFF",
    },
    tabs: {
      1: {
        title: "Cost Reduction",
        content:
          "Minimize repair costs and fuel consumption by monitoring your assets' working profiles",
      },
      2: {
        title: "Time Management",
        content:
          "Now you know exactly what equipment is available and where to quickly locate it, significantly reducing lost working hours",
      },
      3: {
        title: "ESG Compliance",
        content:
          "Start managing basic carbon emission reports, produced from vehicles and machineries. Take the first step towards the ESG era",
      },
      4: {
        title: "Minimize theft",
        content:
          "Proactively safeguard your equipment after a long day. No worries, we will notify you if there is something left behind your indicated safe zone.",
      },
    },
  },

  solutions: {
    title: "Systems Solutions Created For Every Industry",
    subtitle: "WE FULFILL OUR CUSTOMERS NEEDS",
  },

  faq: {
    title: "Frequently Asked Questions",
    subtitle: "LEARN MORE ABOUT",
    1: {
      title: "How can I install it ?",
      content:
        "The device is plug-and-play. You just need to glue it in the proposed place and the device will be up and running.",
    },
    2: {
      title: "How much it will cost me ?",
      content:
        "You can pay at fixed price or at SaaS-base model. We provide major discounts for large quantities. Please send us here for a quote.",
    },
    3: {
      title: "Do I need to replace the batteries ?",
      content:
        "You need to recharge it when the app told you so. This happens once every 1 to 3 months.",
    },

    partnership: "In Partnership With",
    why: "See For Yourself Why Successful Teams Move Faster In Embneusys",
    scheduleDemo: "schedule a demo",
    or: "or",
    contact: "contact our team",
  },

  testimonials: {
    title: "We Are",
    1: {
      person: "Pavlos Kosmidis, Owner",
      content:
        "“The Cube enables to see how our drills really perform on the field and how we can make them work better”",
    },
    2: {
      person: "Kostas Pappas, Mine Director",
      content:
        "“Embneusys’ solution will give us a first insight about what happens in the mine and how we can cut down operational costs”",
    },
    3: {
      person: "Johnatan Garcia Gonzalez, Technical Manager",
      content:
        "“Embneusys’ solution is bringing us one step closer to achieve operational and green efficiency in the mines”",
    },
  },
  marquee: {
    title: "LATEST NEWS",
    text: 'The enterprise «EMBNEUSYS PC» based in Central Macedonia Region, has joined the Action “Elevating Greek Startups against COVID 19 2nd CYCLE” with a total budget of  34 million €. The Action aims at the support of start-ups included in the National Register of Start-ups "Elevate Greece" in the form of a non-refundable grant as working capital to cover their expenses.',
  },
  team: {
    title: "Those who share the vision",
    subtitle: "And support the mission",
    1: {
      name: "Georgios Foroglou (CEO - Sales)",
      photo: "@/assets/images/team/foroglou.jpeg",
      linkedin: "https://linkedin.com/in/georgeforoglou/",
      text: "George is a graduate from University of Macedonia. He has participated in finance projects (CFA Research Challenge), has interned in the Greek Stock Exchange and has received distinctions on business & tech competitions (Hult Prize, Hilti IoT Competition)",
    },
    2: {
      name: "Georgios Kyriazidis (CTO)",
      photo: "@/assets/images/team/kyriazidis.jpeg",
      linkedin: "https://linkedin.com/in/georgios-kyriazidis/",
      text: "George is pursuing a PhD in Electrical Engineering (Harvard University), he holds a MSc in Electrical Engineering and has interned at Hilti R&D",
    },
    3: {
      name: "Lefteris Adalakis (COO)",
      photo: "@/assets/images/team/adalakis.jpeg",
      linkedin: "https://linkedin.com/in/eleftherios-adalakis-a0b256158/",
      text: "Eleftherios holds an MSc in Electrical Engineering (Aristotle University), has interned at Hilti IoT and has been a Product Manager at Hilti Marketing. Currently a Front End developer at Cognizant (CH)",
    },
  },
};
